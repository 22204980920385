<template>
  <div class="main">

    <div class="row">
      <div class="col-md-12 app-title">
        <h1>{{ getTitle() }}</h1>
      </div>
    </div>

    <cc-search 
      :metadata="metadata.filters"
      :useCollapseTags="true"
      :initialSearchText="searchOptions.searchText"
      @searchFilterChanged="handleSearchFilterChanged" />

      <div class="fit-content">
    <cc-var-list
      v-loading.body="fetching"
      :items="filteredReportRows"
      :metadata="metadata"
      baseroute="reports"
      :highlightCurrentRow="true"
      :readOnly="true"
      :hideActions="true"
      :rightAlign="true"
      :rootEntity="searchOptions"
      @linkMethodCallback="linkMethodCallback"
    />
    </div>
  </div>
  
  
</template>

<script>

import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'CcProjectHealth',

  methods: {},

  props: {
    //reportDataUrl: null,
    //baseroute: null,
    //chartHeight: null,
  },

  data() {
    return {
      metadata: {},
      reportRows: [],
      searchOptions: { },
    }
  },

  created() {
    if (this.$route.query.searchText) {
      this.searchOptions.searchText = this.$route.query.searchText;
    }
  },

  mounted() {
    this.fetch()
  },

  computed: {
    ...mapGetters(['globalMetadata']),
    ...mapState({
      fetching: (state) => state.fetching,
    }),

    filteredReportRows() {
      if(!this.searchOptions.searchText || this.searchOptions.searchText == '')
        return this.reportRows;

      return this.reportRows.filter(rr => 
        rr.orderDisplayId.includes(this.searchOptions.searchText));
    },
  },

  methods: {
    ...mapActions(['setFetching']),

    fetch() {
      const uri = 'v1/reporting/projecthealth'

      this.setFetching({ fetching: true })

      this.$http.get(uri).then((res) => {
        this.metadata = res.data.metadata;
        this.reportRows = res.data.reportRows;

        this.setFetching({ fetching: false })
      })
    },

    handleSearchFilterChanged(searchOptions) {
      this.searchOptions = { ...searchOptions };
    },

    linkMethodCallback(methodName, paramsArr) {
      // todo - delete if not needed
      /*switch (methodName) {
        case 'showWorkerWorkLogsSheet':
          this.dialogWorkerWorkLogsSheetArgs.workerId = paramsArr[0];
          this.dialogWorkerWorkLogsSheetArgs.workerName = paramsArr[1];
          this.dialogWorkerWorkLogsSheetArgs.dateRange = paramsArr[2] && paramsArr[3]
            ? [paramsArr[2],paramsArr[3]]
            : this.searchOptions.dateRange || this.metadata.filters.dateRange
          this.dialogWorkerWorkLogsSheetVisible = true;
          break;
      }*/
    },
  },
}
</script>