<template>
  <div>

      <div v-if="getIsMinimized" class ="totals fixed totals-collapsed" >
        <el-form-item label-width="0px" style="justify-content: flex-end;">
          <el-button
            v-if="getIsExpandable"
            style="float: left"
            @click="setTotalsMinimized({ totalsMinimized: false })"
            link
            icon="ArrowUp"
            size="large"
            type = 'primary'
            ></el-button>
          <div
            class="totals-value-minimized"
            v-for="(item, itemIdx) in totals()"
            v-show="item.meta.type != 'calculatedHidden'"
            :title="translate(item.meta.text)"
            :v-model="item.entity[item.propName]"
            :key="itemIdx"
            style="width: initial;"
          >
            {{ getMinimizedItemValue(item.entity, item.propName, item.meta) }}
          </div>
        </el-form-item>
      </div>

      <div v-else class ="totals fixed totals-expanded">
        <el-form-item label-width="0px">
        <el-button
          style="float: left"
          @click="setTotalsMinimized({ totalsMinimized: true })"
          link
          :icon="ArrowDown"
          size="large"
          type = 'primary'
          >{{translate('total')}}</el-button>
        </el-form-item>
        <el-form-item
          v-for="(item, itemIdx) in totals()"
          :label="translate(item.meta.text)"
          label-width="240px"
          :key="itemIdx"
          v-show="item.meta.type!='calculatedHidden'"
        >
          <cc-var-control
            :metadata="item.meta"
            :entity="item.entity"
            :property="item.propName"
            :rootEntity="rootEntity"
            additionalClass="totals-company-coeficient-input"
            :readOnly="readOnly"
          />
        </el-form-item>
      </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { ArrowDown, ArrowUp } from '@element-plus/icons-vue'
import { markRaw } from 'vue'

/* eslint-disable */
export default {
  name: 'CcTotals',

  props: {
    metadata: {},
    entity: {}, // inquiry item or order item
    entities: {}, // list of items. Only one of 'entities' or 'entity' must be specified
    rootEntity: {}, // inquiry or order
    notExpandable: false,
    readOnly: false,
  },

  data() {
    return {
      ArrowUp: markRaw(ArrowUp),
      ArrowDown: markRaw(ArrowDown),
    }
  },

  computed: {
    ...mapState({
      totalsMinimized: (state) => state.totalsMinimized,
    }),

    getIsMinimized() {
      return this.totalsMinimized || this.notExpandable
    },

    getIsExpandable() {
      return !this.notExpandable
    },
  },

  methods: {
    ...mapActions(['setTotalsMinimized']),

    mounted() {},

    totals() {
      var result = []

      var isSingle = !!this.entity && !!this.rootEntity
      var isList = !!this.entities

      if (!this.metadata || !(isSingle || isList)) {
        return result
      }

      for (var i = 0; i < this.metadata.length; i++) {
        var meta = this.metadata[i]

        if (isSingle) {
          var val = this.getTotalsValueSingle(meta)
          result.push(val)
        } else if (isList) {
          var val = this.getTotalsValueList(meta)
          result.push(val)
        }
      }

      return result
    },

    getTotalsValueSingle(meta) {
      var entity = this.entity
      var path = meta.id

      if (path.startsWith('rootEntity.')) {
        path = meta.id.substring('rootEntity.'.length)
        entity = this.rootEntity
      }

      var hierObj = this.findHierObjectAndPropertyName(entity, path)

      return { meta: meta, entity: hierObj.entity, propName: hierObj.propName }
    },

    getTotalsValueList(meta) {
      if (meta.type == 'calculated') {
        var value = this.getTotalsValueListCalculated(meta)
        return { meta: meta, entity: { value: value }, propName: 'value' }
      } else {
        console.error(`Unknown column type '${meta.type}' for list totals`)
      }
    },

    getTotalsValueListCalculated(meta) {
      var items = this.entities
      try {
        var val = eval(meta.formula)
        return val
      } catch (e) {
        console.error('ERROR: evaluateFormula:')
        console.error(e.message)
        return ''
      }
    },

    getMinimizedItemValue(item, propName, meta) {
      if(meta.type=='calculated' || meta.type == 'calculatedHidden') {
        var rootEntity = this.rootEntity
        const that = this
        try {
          item[propName] = eval(meta.formula)
        } catch (e) {
          console.error('ERROR: evaluateFormula: ' + e.message + '; formula: ' + meta.formula)
        }
        
      }

      return meta.suffix  ? `${item[propName]} ${meta.suffix}` : item[propName]
    },
  },
}
</script>

<style scoped>

</style>
