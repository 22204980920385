const Reports = () => import('./main.vue')
const WorkcenterBookedTime = () => import('./workcenterbookedtime.vue')
const WorkerPerformanceHistory = () => import('./workerperformancehistory.vue')
const Timesheet = () => import('./timesheet.vue')
const ResourceAllocationByWorkcenter = () => import('./resourceallocationbyworkcenter.vue')
const CurrentActivityMap = () => import('./currentactivitymap.vue')
const DeliveryPerformance = () => import('./deliveryperformance.vue')
const SupplierDeliveryPerformance = () => import('./supplierdeliveryperformance.vue')
const ContractorDeliveryPerformance = () => import('./contractordeliveryperformance.vue')
//old const OrdersExecutionOverview = () => import('./ordersexecutionoverview.vue')
const ProjectHealth = () => import('./projecthealth.vue')
const ProjectProgress = () => import('./projectprogress.vue')


const meta = {
  requiresAuth: true,
}

export default [
  {
    name: 'reports',
    path: '/reports',
    component: Reports,
    meta,
    children: [
      {
        name: 'reports.workcenterbookedtime',
        path: '/reports/workcenterbookedtime',
        component: WorkcenterBookedTime,
        meta,
      },
      {
        name: 'reports.workerperformancehistory',
        path: '/reports/workerperformancehistory',
        component: WorkerPerformanceHistory,
        meta,
      },
      {
        name: 'reports.timesheet',
        path: '/reports/timesheet',
        component: Timesheet,
        meta,
      },
      {
        name: 'reports.resourceallocationbyworkcenter',
        path: '/reports/resourceallocationbyworkcenter',
        component: ResourceAllocationByWorkcenter,
        meta,
      },
      {
        name: 'reports.currentactivitymap',
        path: '/reports/currentactivitymap',
        component: CurrentActivityMap,
        meta,
      },
      {
        name: 'reports.deliveryperformance',
        path: '/reports/deliveryperformance',
        component: DeliveryPerformance,
        meta,
      },
      {
        name: 'reports.supplierdeliveryperformance',
        path: '/reports/supplierdeliveryperformance',
        component: SupplierDeliveryPerformance,
        meta,
      },
      {
        name: 'reports.contractordeliveryperformance',
        path: '/reports/contractordeliveryperformance',
        component: ContractorDeliveryPerformance,
        meta,
      },
      /*old: {
        name: 'reports.ordersexecutionoverview',
        path: '/reports/ordersexecutionoverview',
        component: OrdersExecutionOverview,
        meta,
      },*/
      {
        name: 'reports.projecthealth',
        path: '/reports/projecthealth',
        component: ProjectHealth,
        meta,
      },
      {
        name: 'reports.projectprogress',
        path: '/reports/projectprogress',
        component: ProjectProgress,
        meta,
      },
    ],
  },
      {
        name: 'reports.workcenterbookedtime',
        path: '/reports/workcenterbookedtime',
        component: WorkcenterBookedTime,
        meta,
      },
      {
        name: 'reports.workerperformancehistory',
        path: '/reports/workerperformancehistory',
        component: WorkerPerformanceHistory,
        meta,
      },
      {
        name: 'reports.timesheet',
        path: '/reports/timesheet',
        component: Timesheet,
        meta,
      },
      {
        name: 'reports.resourceallocationbyworkcenter',
        path: '/reports/resourceallocationbyworkcenter',
        component: ResourceAllocationByWorkcenter,
        meta,
      },
      {
        name: 'reports.currentactivitymap',
        path: '/reports/currentactivitymap',
        component: CurrentActivityMap,
        meta,
      },
      {
        name: 'reports.deliveryperformance',
        path: '/reports/deliveryperformance',
        component: DeliveryPerformance,
        meta,
      },
      {
        name: 'reports.supplierdeliveryperformance',
        path: '/reports/supplierdeliveryperformance',
        component: SupplierDeliveryPerformance,
        meta,
      },
      {
        name: 'reports.contractordeliveryperformance',
        path: '/reports/contractordeliveryperformance',
        component: ContractorDeliveryPerformance,
        meta,
      },
      /* old: {
        name: 'reports.ordersexecutionoverview',
        path: '/reports/ordersexecutionoverview',
        component: OrdersExecutionOverview,
        meta,
      },*/
      {
        name: 'reports.projecthealth',
        path: '/reports/projecthealth',
        component: ProjectHealth,
        meta,
      },
      {
        name: 'reports.projectprogress',
        path: '/reports/projectprogress',
        component: ProjectProgress,
        meta,
      },
      
]
