import store from '../store'
import { currentUser  } from '../app/auth/vuex/getters'

const needAuth = (auth) => auth === true

const findDefaultMenuItem = (mainMenu) => {
  if(mainMenu) {
    const result = mainMenu.find(n => n.isDefault);
    if(result)
      return result;

    const resultParent = mainMenu.find(n => n.subMenuItems && n.subMenuItems.find(x => x.isDefault));
    if(resultParent)
      return resultParent.subMenuItems.find(x => x.isDefault);
  }

  return null;
}

const beforeEach = (to, from, next) => {
  const auth = to.meta.requiresAuth
  //const role = to.meta.role
  
  if (!needAuth(auth)) {
    next()
    return // return to prevent the code from continuing in its flow
    // With this flow `else` or `else if` is not necessary
  }

  /**
   * Otherwise  if authentication is required login.
   */
  store
    .dispatch('checkUserToken')
    .then(() => {
      if(to.path === '/') {
        var defaultMenuItem ;
        if(store.state.Auth.user.isShopFloorWorker) {
          next('/shopfloorcontrol') // can access the route
        } else if (defaultMenuItem = findDefaultMenuItem(store.state.Auth.user.mainMenu)) {
          next({ name: defaultMenuItem.id })
        } else {
          next()    
        }
      } else {
        next() // can access the route
      }
    })
    .catch(() => {
      // No token, or it is invalid
      next({ name: 'auth.signin' }) // redirect to login
    })
}

export default beforeEach
